#landing {
  background-color: #fff;
  /* color: aqua; */
}

#landing .navbar.scrolling-navbar.top-nav-collapse {
  background-color: #0077b6 !important;
}

#landing #home .view {
  background: url("./../../assets/bg.jpg") no-repeat center center;
  background-size: cover;
  height: 100vh;
}

#landing .rgba-gradient {
  background: -webkit-linear-gradient(
    45deg,
    rgba(83, 125, 210, 0.4),
    rgba(178, 30, 123, 0.4) 100%
  );
}

#landing h6 {
  line-height: 1.7;
}

#landing .card {
  background-color: rgba(229, 228, 255, 0.2);
}

#landing .avatar {
  max-width: 150px;
  height: auto;
}

#landing footer.page-footer {
  background-color: #0077b6;
}
