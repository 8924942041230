.card-img {
  height: 300px;
}
@media (max-width: 768px) {
  .card-img {
    height: 20px;
  }
  .profile-img {
    height: 40px;
  }
}
